/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { Grid } from '@material-ui/core';
import styles from './index.module.scss';
import { compose, propOr } from 'ramda';
import { ppiAnswerOptions } from './lib';
import { RadioSet, TextField } from 'components/fields';
import { AccordionPanelStyled } from '@nike/nike-design-system-components';
import Fields from './Fields';
import { fieldsTypes, formTypes } from 'lib/propTypes';
import { INQUIRIES } from './utils';
import { Text } from '@nike/eds';
import { isArray } from 'lodash';
import {
  QuestionSetTypes,
  getFieldValuesByFieldNames,
} from './RepeatableFieldsetUtils';
import { requiredError } from '../utils';

export const excludeAnswerOptions = ['ppi-height', 'ppi-weight'];

const InquiryQuestion = (props) => {
  const {
    questionIndex,
    formState,
    fields,
    setFieldValue,
    setFormState,
    errors,
    disabled,
    handleChange,
    layoutType,
    isApproved,
  } = props;

  const [answerFormat, setAnswerFormat] = useState();
  const [, setDisablePPISelection] = useState();

  const showAnswerFormat = false;
  const fieldSetId = fields.fieldSetId;

  const [{ value: answerOptions }] = useField(`answerOptions-${ fieldSetId }`);

  const {
    questionLabel,
    conditionalToggle: isCurrentIndexConditional,
    questionCategory: { value: categoryLabel } = {},
    existingPPI: { value: existingPPI } = {},
  } = getFieldValuesByFieldNames(fields?.value, [
    QuestionSetTypes.QUESTION_LABEL,
    QuestionSetTypes.QUESTION_CATEGORY,
    QuestionSetTypes.EXISTING_PPI,
    QuestionSetTypes.CONDITIONAL_TOGGLE,
  ]);

  const dynamicQuestionLabel = compose(propOr(null, 'dynamicQuestionLabel'))(
    formState?.[questionIndex]
  );

  useEffect(() => {
    //Can not change PPI Selection if options are provided
    setDisablePPISelection(answerOptions && answerOptions.length > 0);
  }, [answerOptions]);

  useEffect(() => {
    const answerOptionsConstants = (existingPPI) => {
      const response = ppiAnswerOptions(existingPPI);
      return response;
    };

    if (existingPPI) {
      setAnswerFormat(answerOptionsConstants(existingPPI) ? 'ppi-set' : 'enum');
    }
  }, [existingPPI]);

  function transformQuestionSet(index, fieldSetId) {
    switch (index) {
      case 0:
        return `${ QuestionSetTypes.QUESTION_CATEGORY }-${ fieldSetId }`;
      case 1:
        return `${ QuestionSetTypes.CATEGORY_NAME }-${ fieldSetId }`;
      case 2:
        return `${ QuestionSetTypes.CATEGORY_TITLE }-${ fieldSetId }`;
      case 3:
        return `${ QuestionSetTypes.CATEGORY_SUBTITLE }-${ fieldSetId }`;
      case 4:
        return `${ QuestionSetTypes.CATEGORY_TEXT }-${ fieldSetId }`;
      case 7:
        return `${ QuestionSetTypes.CONDITIONAL_TOGGLE }-${ fieldSetId }`;
      case 8:
        return `${ QuestionSetTypes.CONDITIONAL_OPTIONS }-${ fieldSetId }`;
      case 9:
        return `${ QuestionSetTypes.QUESTION_LABEL }-${ fieldSetId }`;
      case 10:
        return `${ QuestionSetTypes.EXISTING_PPI }-${ fieldSetId }`;
      case 11:
        return `${ QuestionSetTypes.ANSWER_TYPE }-${ fieldSetId }`;
      case 12:
        return `${ QuestionSetTypes.ANSWER_OPTIONS }-${ fieldSetId }`;
      default:
        return null;
    }
  }

  const errorsTransformed = {};
  errors?.value.map((itm, idx) => {
    if (itm) {
      errorsTransformed[transformQuestionSet(idx, fieldSetId)] =
        itm.value || requiredError;
    }
  });

  const sortedFieldSet = isArray(formState)
    ? formState.map((fieldSet) =>
      fieldSet.value.sort(
        (field1, field2) => field1.sortOrder - field2.sortOrder
      )
    )
    : [];

  useEffect(() => {
    setFormState(sortedFieldSet);
  }, []);

  const choiceConditionMet = fields.value?.some(
    (item) => item.type === 'choice' && item.value === true
  );

  const cardLabel = useMemo(() => {
    if (typeof questionLabel === 'string') {
      return isCurrentIndexConditional
        ? questionLabel
        : typeof categoryLabel === 'string'
          ? `${ categoryLabel }: ${ questionLabel }`
          : dynamicQuestionLabel || '';
    }
    return dynamicQuestionLabel || '';
  }, [
    questionLabel,
    categoryLabel,
    dynamicQuestionLabel,
    isCurrentIndexConditional,
  ]);

  return (
    <AccordionPanelStyled
      id={ `question-${ fieldSetId }` }
      open={ true }
      className={ `${ styles.accordionPanel } ${
        !isCurrentIndexConditional ? styles.accordionPanelMargin : ''
      }` }
      detailsTitle={
        <Text as="h3" className={ styles.detailsTitle } id={ fieldSetId }>
          { cardLabel }
        </Text>
      }
      animatable={ true }
    >
      <Grid
        container={ true }
        direction="row"
        spacing={ 0 }
        className={ styles.fieldSetGrid }
      >
        <Grid container={ true } direction="row">
          <Grid item={ true } xs={ 12 }>
            <Fields
              disabled={ disabled }
              formState={ formState }
              handleChange={ handleChange }
              setFieldValue={ setFieldValue }
              setFormState={ setFormState }
              errors={ errorsTransformed }
              fields={ fields.value.filter((val) => val.sortOrder < 12) }
              layoutType={ layoutType }
              isApproved={ isApproved }
              choiceConditionMet={ choiceConditionMet }
              disableCategoryChoice={ !!questionLabel }
            />
          </Grid>

          { showAnswerFormat && (
            <Grid container={ true } direction="row" spacing={ 3 }>
              <Grid item={ true } xs={ 12 }>
                <RadioSet
                  disabled={ answerFormat === 'ppi-set' }
                  name={ `answerFormat-${ fieldSetId }` }
                  multiple={ false }
                  label="Answer Format"
                  selectedValue={ answerFormat }
                  options={ [
                    { label: 'Text', value: 'enum' },
                    { label: 'Number', value: 'number', disabled: true },
                    {
                      label: 'Standard for this PPI pre-entered',
                      value: 'ppi-set',
                      disabled: true,
                    },
                  ] }
                />
              </Grid>
            </Grid>
          ) }

          { answerFormat === 'enum' && (
            <Grid container={ true } direction="row" spacing={ 3 }>
              <Grid item={ true } xs={ 12 }>
                <Fields
                  disabled={ disabled }
                  formState={ formState }
                  handleChange={ handleChange }
                  setFieldValue={ setFieldValue }
                  setFormState={ setFormState }
                  errors={ errorsTransformed }
                  fields={ fields.value.filter((val) => val.sortOrder >= 12) }
                  layoutType={ layoutType }
                  isApproved={ isApproved }
                  choiceConditionMet={ choiceConditionMet }
                  disableCategoryChoice={ !!questionLabel }
                />
              </Grid>
            </Grid>
          ) }
          { answerFormat === 'ppi-set' &&
            INQUIRIES.includes(layoutType) &&
            !excludeAnswerOptions.includes(existingPPI) && (
            <Grid container={ true } direction="row" spacing={ 3 }>
              <Grid item={ true } xs={ 12 }>
                <Fields
                  disabled={ disabled }
                  formState={ formState }
                  handleChange={ handleChange }
                  setFieldValue={ setFieldValue }
                  setFormState={ setFormState }
                  errors={ errorsTransformed }
                  fields={ fields.value.filter((val) => val.sortOrder >= 12) }
                  existingPPI={ existingPPI }
                  layoutType={ layoutType }
                  isApproved={ isApproved }
                  choiceConditionMet={ choiceConditionMet }
                  disableCategoryChoice={ !!questionLabel }
                />
              </Grid>
            </Grid>
          ) }
          { answerFormat === 'number' && (
            <Grid item={ true } xs={ 12 }>
              <Grid xs={ 12 }>
                <Text font="title-6" as="h6">
                  Answer Numeric Range
                </Text>
              </Grid>
              <Grid
                sm={ true }
                container={ true }
                xs={ 12 }
                className={ styles.rowWrapper }
              >
                <Grid sm={ true } item={ true }>
                  <TextField name="answerMin" type="number" label="Min" />
                </Grid>
                <Text
                  font="body-1"
                  style={{ float: 'left', padding: '50px 30px' }}
                >
                  to
                </Text>
                <Grid sm={ true } item={ true }>
                  <TextField name="answerMax" type="number" label="Max" />
                </Grid>
                <Grid sm={ true } item={ true }>
                  <Text
                    font="body-1"
                    style={{ float: 'left', padding: '50px 30px' }}
                  >
                    by
                  </Text>
                </Grid>

                <Grid sm={ true } item={ true }>
                  <TextField
                    name="iterateBy"
                    type="number"
                    label="Iteration"
                  />
                </Grid>
              </Grid>
              <Grid item={ true } xs={ 12 }>
                <TextField name="answerUnits" label="Answer Units" />
              </Grid>
            </Grid>
          ) }
          { answerFormat === 'pattern' && (
            <Grid item={ true } xs={ 12 }>
              <TextField
                name="answerPattern"
                label="Answer Pattern Requirements"
              />
            </Grid>
          ) }
        </Grid>
      </Grid>
    </AccordionPanelStyled>
  );
};

InquiryQuestion.propTypes = {
  errors: PropTypes.shape({}),
  isApproved: PropTypes.bool,
  layoutType: PropTypes.string,
  questionIndex: PropTypes.number,
  ...formTypes,
  fields: fieldsTypes.inquiryData,
};

export default InquiryQuestion;
