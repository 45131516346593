/* eslint-disable complexity */
import React from 'react';
import ConditionalToggle from 'components/fields/ConditionalToggleField';
import { Grid } from '@material-ui/core';
import { TextArea } from '@nike/eds';
import { RichTextField } from 'components/fields';
import { Field } from 'formik';
import { getFieldLabel, ppiAnswerOptions, validationSchema } from './lib';
import { propOr } from 'ramda';
import { SCREENER_FORM, SURVEY_FORM } from 'lib/layoutUtils';
import { getRequiredFields } from 'utils';
import { ChoiceComponent } from './Components/Choice';
import { ImageField } from './Components/ImageField';
import { TextComponent } from './Components/Text';
import { RichTextComponent } from './Components/RichText';
import { TagsInputField } from './Components/TagsInputField';
import { InquirySelectField } from './Components/InquirySelectField';
import { InquiryRadioField } from './Components/InquiryRadioField';

export const INQUIRIES = [SURVEY_FORM, SCREENER_FORM];

// eslint-disable-next-line
const renderField = (field, onChange, errors, disabled, isApproved) => {
  const {
    message: fieldHelperText,
    field: fieldName,
    type: fieldType,
    value: fieldValue,
    label: fieldLabel,
    placeholder,
    sortOrder: fieldOrder,
    options: fieldOptions,
    isCreatable,
    existingPPI,
    layoutType,
    formState,
    values,
    className,
    disabled: fieldDisabled,
    showCategoryContent,
    setShowCategoryContent,
  } = field;

  const fieldId = `${ fieldName }-${ fieldType }`;
  const errorFiltered = Array.isArray(errors?.sections)
    ? errors.sections
      .filter((_, index) => index === fieldOrder - 1)
      .map((itm) => (itm ? { error: itm?.value } : ''))
      .pop()
    : propOr('', fieldName, errors);

  const errorMessage = propOr(null, 'error', errorFiltered) || errorFiltered;
  const hasErrors = errorMessage;
  const isCorePPI = !!ppiAnswerOptions(existingPPI);
  const getValidationSchema = validationSchema[layoutType];
  //eslint-disable-next-line no-underscore-dangle
  const _validationSchema =
    layoutType === SURVEY_FORM
      ? getValidationSchema(false, false)
      : getValidationSchema;
  const requiredFields = getRequiredFields(_validationSchema);

  const updatedClassName =
    className === 'categoryFields'
      ? showCategoryContent
        ? 'showContent'
        : 'hideContent'
      : className;

  const fieldMap = (fieldType) => {
    switch (fieldType) {
      case 'text':
        return (
          <TextComponent
            fieldId={ fieldId }
            fieldLabel={ fieldLabel }
            fieldType={ fieldType }
            fieldValue={ fieldValue }
            fieldHelperText={ fieldHelperText }
            fieldName={ fieldName }
            className={ updatedClassName }
            onChange={ onChange }
            disabled={ disabled }
            requiredFields={ requiredFields }
            showCategoryContent={ showCategoryContent }
            hasErrors={ !!hasErrors }
            placeholder={ placeholder }
            errorMessage={ errorMessage }
          />
        );
      case 'textArea':
        return (
          <Field
            key={ fieldId }
            name={ fieldName }
            placeholder={ placeholder || fieldName }
          >
            { ({ field, meta }) => {
              return (
                <TextArea
                  className="eds-spacing--mb-24"
                  key={ fieldId }
                  type={ fieldType }
                  id={ fieldName }
                  hasErrors={ !!meta.error }
                  errorMessage={ meta.error }
                  label={
                    <label>
                      { fieldLabel || getFieldLabel(field.name) }
                      { requiredFields[fieldName] && !meta.error && (
                        <span className="asterisk">*</span>
                      ) }
                    </label>
                  }
                  message={ fieldHelperText }
                  rows={ 3 }
                  value={ field.value }
                  onChange={ onChange }
                />
              );
            } }
          </Field>
        );
      case 'richText':
        return (
          <RichTextComponent
            fieldId={ fieldId }
            fieldName={ fieldName }
            fieldType={ fieldType }
            fieldLabel={ fieldLabel }
            placeholder={ placeholder }
            hasErrors={ !!hasErrors }
            errorMessage={ errorMessage }
            values={ values }
            className={ updatedClassName }
            disabled={ disabled }
            onChange={ onChange }
            message={ fieldHelperText }
          />
        );
      case 'repeatingText':
        return (
          <Field
            key={ fieldId }
            name={ fieldName }
            placeholder={ placeholder || fieldName }
          >
            { ({ field }) => (
              <RichTextField
                hasErrors={ !!hasErrors }
                errorMessage={ errorMessage }
                disabled={ disabled }
                key={ fieldId }
                type={ fieldType }
                id={ fieldName }
                value={ field.value || fieldValue }
                label={
                  <label>
                    { getFieldLabel(fieldType, fieldOrder) }
                    <span
                      className={ `asterisk ${ hasErrors ? 'hasErrors' : '' }` }
                    >
                      *
                    </span>
                  </label>
                }
                onChange={ onChange }
              />
            ) }
          </Field>
        );
      case 'select':
        return (
          <InquirySelectField
            fieldId={ fieldId }
            fieldName={ fieldName }
            fieldLabel={ fieldLabel }
            fieldValue={ fieldValue }
            formState={ formState }
            errors={ errors }
            hasErrors={ !!hasErrors }
            onChange={ onChange }
            disabled={ disabled }
            fieldOptions={ fieldOptions }
            errorMessage={ errorMessage }
            isCreatable={ isCreatable }
            showCategoryContent={ showCategoryContent }
            setShowCategoryContent={ setShowCategoryContent }
          />
        );
      case 'radioSet':
        return (
          <InquiryRadioField
            disabled={ disabled }
            fieldId={ fieldId }
            fieldName={ fieldName }
            fieldLabel={ fieldLabel }
            fieldValue={ fieldValue }
            hasErrors={ !!hasErrors }
            errorMessage={ errorMessage }
            placeholder={ placeholder }
            onChange={ onChange }
            layoutType={ layoutType }
            fieldOptions={ fieldOptions }
            formState={ formState }
            questionSet={ values.questionSet }
          />
        );
      case 'tagsInput':
        return (
          <TagsInputField
            fieldId={ fieldId }
            fieldLabel={ fieldLabel }
            fieldName={ fieldName }
            fieldValue={ fieldValue }
            placeholder={ placeholder }
            disabled={ disabled }
            hasErrors={ !!hasErrors }
            errorMessage={ errorMessage }
            formState={ formState }
            questionSet={ values.questionSet }
            errors={ errors }
            layoutType={ layoutType }
            onChange={ onChange }
            isCorePPI={ isCorePPI }
            existingPPI={ existingPPI }
            isApproved={ isApproved }
          />
        );
      case 'toggle':
        return (
          <ConditionalToggle
            formState={ formState }
            fieldValue={ !!fieldValue }
            fieldName={ fieldName }
            onChange={ onChange }
            fieldId={ fieldId }
          />
        );
      case 'choice':
        return (
          <ChoiceComponent
            fieldId={ fieldId }
            fieldName={ fieldName }
            className={ updatedClassName }
            fieldLabel={ fieldLabel }
            onChange={ onChange }
            disabled={ !!(disabled || fieldDisabled) }
            fieldValue={ fieldValue }
          />
        );
      case 'backgroundImage':
        return (
          <ImageField
            fieldId={ fieldId }
            fieldValue={ fieldValue }
            fieldName={ fieldName }
            className={ updatedClassName }
            fieldLabel={ fieldLabel }
            placeholder={ placeholder }
            onChange={ onChange }
          />
        );
    }
  };

  return (
    <Grid
      item={ true }
      key={ `${ fieldId }-container` }
      style={ INQUIRIES.includes(layoutType) ? { position: 'relative' } : {} }
    >
      { fieldMap(fieldType) }
    </Grid>
  );
};

export const getFieldType = (field) => {
  return field?.value?.type ? field?.value[0]?.type : field?.type;
};

export default renderField;
