/* eslint-disable complexity */
/* eslint-disable max-statements */
import * as Yup from 'yup';
import { propOr, sum } from 'ramda';
import { weightOptions, heightOptions } from 'lib/ppiOptions.js';
import { isEmpty, kebabCase } from 'lodash';
import { marked } from 'marked';

import {
  MOBILE_DETAIL_SCREEN,
  WEB_DETAIL_PAGE,
  WEB_OVERVIEW_CARD,
} from 'lib/layoutUtils';
import {
  atLeastTwoOptionsError,
  invalidCharacterError,
  duplicateAnswerOption,
  requiredError,
  isEmptyValue,
  isTextOrHiddenType,
  isValidCustomVariable,
  nonSpecialCharRegex,
  nonSpecialCharRegexWithCustomVariable,
  uuidFormatError,
  specialCharRegexWithCustomVariable,
  customVariableSupportText as message,
} from '../utils';
import {
  QuestionSetTypes,
  getFieldByFieldName,
  getFieldValueByFieldName,
  getFieldValuesByFieldNames,
} from './RepeatableFieldsetUtils';

export const INITIAL_VALUES = {
  tagline: '',
  headline: '',
};

export const ppiAnswerOptions = (ppiHandle) => {
  const ppiChoices = JSON.parse(
    window.localStorage.getItem('ppi-answer-options')
  )?.ppiAnswerOptions;

  switch (ppiHandle) {
    case 'ppi-height':
      return heightOptions(36, 95);
    case 'ppi-weight':
      return weightOptions(60, 500);
    case 'ppi-shoe-size':
      return ppiChoices?.shoeSizes?.options;
    case 'ppi-mens-shoe-size':
      return ppiChoices?.shoeSizes?.options;
    case 'ppi-womens-shoe-size':
      return ppiChoices?.shoeSizes?.options;
    case ppiChoices?.mensTopSizes?.ppiType:
      return ppiChoices?.mensTopSizes?.options;
    case ppiChoices?.mensBottomSizes?.ppiType:
      return ppiChoices?.mensBottomSizes?.options;
    case ppiChoices?.womensTopSizes?.ppiType:
      return ppiChoices?.womensTopSizes?.options;
    case ppiChoices?.womensBottomSizes?.ppiType:
      return ppiChoices?.womensBottomSizes?.options;
    case ppiChoices?.activityChoices?.ppiType:
      return ppiChoices?.activityChoices?.options;
    case ppiChoices?.genderIdentity?.ppiType:
      return ppiChoices?.genderIdentity?.options;
    case ppiChoices?.nikeAffiliationChoices?.ppiType:
      return ppiChoices?.nikeAffiliationChoices?.options;
    case ppiChoices?.raceChoices?.ppiType:
      return ppiChoices?.raceChoices?.options;
    case ppiChoices?.portlandArea?.ppiType:
      return ppiChoices?.portlandArea?.options;
    default:
      return null;
  }
};

const stringValidation = (maxChars = 1000) =>
  Yup.string()
    .max(maxChars)
    .test('required', requiredError, (value) => {
      return value !== undefined && value !== null && value.trim() !== '';
    });

const imageFieldsValidation = () =>
  Yup.string().required(requiredError)
    .nullable();

const richTextValidation = (maxChars) =>
  Yup.string()
    .transform((value, originalValue) => {
      if (originalValue) {
        const markdown = marked(value);
        const div = document.createElement('div');
        div.innerHTML = markdown;
        const textContent = div.textContent || div.innerText || '';
        //Rich text also shows up with \ formatting when blank and few other cases, so need to check for that
        const convertedText = textContent
          .replace(/[\r\n\s\\]+/g, '') // Remove line breaks, whitespace, and backslashes
          .trim(); // Trim any leading or trailing whitespace
        return convertedText;
      }
      return '';
    })
    .test('non empty', requiredError, (value) => !!value?.length)
    .required(requiredError)
    .max(maxChars);

export const defaultAsset = Yup.object().shape({
  title: stringValidation(40),
  subtitle: stringValidation(95),
  backgroundImage: imageFieldsValidation(),
  logoImage: imageFieldsValidation(),
});

const overviewCard = Yup.object().shape({
  title: stringValidation(40),
  subtitle: stringValidation(25),
  text: richTextValidation(6000),
  ctaText: stringValidation(15),
  backgroundImage: imageFieldsValidation(),
  logoImage: imageFieldsValidation(),
});

const webOverviewCard = Yup.object().shape({
  title: stringValidation(40),
  subtitle: stringValidation(25),
  text: richTextValidation(85),
  ctaText: stringValidation(15),
  backgroundImage: imageFieldsValidation(),
  logoImage: imageFieldsValidation(),
});

const mobileOverviewCard = Yup.object().shape({
  title: stringValidation(40),
  subtitle: stringValidation(85),
  ctaText: stringValidation(15),
  backgroundImage: imageFieldsValidation(),
  logoImage: imageFieldsValidation(),
});

const mobileReturningCard = Yup.object().shape({
  backgroundImage: imageFieldsValidation(),
  logoImage: imageFieldsValidation(),
});

const mobileDetailScreen = Yup.object().shape({
  title: stringValidation(40),
  text: richTextValidation(1200),
  subtitle: stringValidation(85),
  ctaText: stringValidation(15),
  backgroundImage: imageFieldsValidation(),
  logoImage: imageFieldsValidation(),
});

const webDetailPage = Yup.object().shape({
  title: stringValidation(60),
  subtitle: stringValidation(95),
  text: richTextValidation(6000),
  ctaText: stringValidation(15),
  backgroundImage: imageFieldsValidation(),
  logoImage: imageFieldsValidation(),
});

const consentDocument = Yup.object().shape({
  sections: Yup.array()
    .required()
    .of(
      Yup.object().shape({
        value: Yup.string()
          //should not contain consecutive empty spaces at the start.
          //Rich text shows up with formatting when blank, so need to check for that
          .matches(/^(?:(?=\n1)|(?!\s))(?!\\\n)/, 'Required'),
      })
    ),
});

const createCustomError = (context, val1, errorMessage = 'Required') =>
  context.createError({
    message: errorMessage,
    path: context.path,
    value: val1,
  });

const checkCategoryFieldValidity = (fields, value, val1, context) => {
  if (
    (fields.includes(QuestionSetTypes.QUESTION_LABEL) ||
      fields.includes(QuestionSetTypes.QUESTION_CATEGORY) ||
      fields.includes(QuestionSetTypes.EXISTING_PPI)) &&
    isEmptyValue(value)
  ) {
    //If questionLabel, questionCategory, existingPPI value is empty -> false
    return false;
  }

  //Category name should not contain special characters and it shouldn't be empty
  if (fields.includes(QuestionSetTypes.CATEGORY_NAME)) {
    if (!isValidCustomVariable(value)) {
      return isEmptyValue(value)
        ? false
        : createCustomError(context, val1, invalidCharacterError);
    }
  }

  //Category title and subtitle should not contain special characters. However they can be empty
  if (
    fields.includes(QuestionSetTypes.CATEGORY_TITLE) ||
    fields.includes(QuestionSetTypes.CATEGORY_SUBTITLE)
  ) {
    if (!isValidCustomVariable(value) && !isEmpty(value)) {
      return createCustomError(context, val1, invalidCharacterError);
    }
  }

  //Category text can be empty and allows special characters too
  if (fields.includes(QuestionSetTypes.CATEGORY_TEXT)) {
    if (
      !isValidCustomVariable(value, specialCharRegexWithCustomVariable) &&
      !isEmpty(value)
    ) {
      return createCustomError(context, val1, invalidCharacterError);
    }
  }

  return null;
};

const screener = Yup.object().shape({
  questionSet: Yup.array().of(
    Yup.object().shape({
      value: Yup.lazy((itm) => {
        const ppiValue = getFieldValueByFieldName(
          itm,
          QuestionSetTypes.EXISTING_PPI
        )?.value;
        const isCorePPI = !!ppiAnswerOptions(ppiValue);
        const isConditionalToggled = getFieldValueByFieldName(
          itm,
          QuestionSetTypes.CONDITIONAL_TOGGLE
        );
        return Yup.array().of(
          Yup.object().shape({
            value: Yup.mixed()
              //eslint-disable-next-line
              .test('required', requiredError, function (val1) {
                const { field: fields, value } = this.parent || {};

                if (fields.includes(QuestionSetTypes.ANSWER_OPTIONS)) {
                  if (['ppi-height', 'ppi-weight'].includes(ppiValue)) {
                    return true;
                  }
                  const options = propOr([], 'value', this.parent);
                  const minimumOptions = options.length >= 2;
                  const hasEmptyValue = options.some(
                    ({ value }) => value === undefined || value === ''
                  );
                  if (!isCorePPI && !minimumOptions) {
                    return createCustomError(
                      this,
                      val1,
                      atLeastTwoOptionsError
                    );
                  }
                  if (hasEmptyValue) {
                    return false;
                  }
                }

                if (fields.includes(QuestionSetTypes.ANSWER_TYPE)) {
                  if (['ppi-height', 'ppi-weight'].includes(ppiValue)) {
                    return true;
                  }
                  if (!value) {
                    return false;
                  }
                }

                if (
                  fields.includes(QuestionSetTypes.CONDITIONAL_OPTIONS) &&
                  isConditionalToggled &&
                  (!value || isEmpty(value))
                ) {
                  return false;
                }

                const validationResult = checkCategoryFieldValidity(
                  fields,
                  value,
                  val1,
                  this
                );
                return validationResult !== null ? validationResult : true;
              })
              //eslint-disable-next-line
              .test('duplicate', 'Duplicate', function (val1) {
                const { field: fields } = this.parent || {};

                if (fields.includes(QuestionSetTypes.ANSWER_OPTIONS)) {
                  const options = propOr([], 'value', this.parent);

                  const duplicateValues = options
                    .map((option) => option.value?.toLowerCase())
                    .filter(
                      (value, index, self) => self.indexOf(value) !== index
                    );

                  const duplicateOption = val1?.find((item) =>
                    duplicateValues.includes(item.value?.toLowerCase())
                  );

                  if (duplicateOption) {
                    const duplicateValue = duplicateOption.value;

                    return createCustomError(
                      this,
                      val1,
                      `${ duplicateAnswerOption }: ${ duplicateValue }`
                    );
                  }
                }

                return true;
              }),
          })
        );
      }),
    })
  ),
});

// Setting the time to midnight for tomorrow to compare
const futureDate = new Date();
futureDate.setDate(futureDate.getDate() + 1);
futureDate.setHours(0, 0, 0, 0);

const survey = (isSurveyStartDateChanged, isSurveyEndDateChanged) => {
  return Yup.object().shape({
    surveyName: Yup.string()
      .matches(nonSpecialCharRegexWithCustomVariable, invalidCharacterError)
      .required(requiredError),
    surveyHandle: Yup.string()
      .matches(nonSpecialCharRegex, invalidCharacterError)
      .max(60, 'Character limit exceeded. Maximum 60 characters allowed.')
      .required(requiredError),
    surveyId: Yup.string()
      .uuid(uuidFormatError)
      .required(requiredError)
      .nullable(),
    isSurveyScheduled: Yup.boolean(),
    scheduleRate: Yup.string().when(['isSurveyScheduled'], {
      is: (isScheduled) => isScheduled,
      then: Yup.string()
        .oneOf(['Once', 'Daily', 'Weekly'])
        .required(requiredError),
      otherwise: Yup.string().nullable(),
    }),
    surveyStartDate: Yup.date().when(['isSurveyScheduled'], {
      is: (isScheduled) => isScheduled,
      then: Yup.date().when(['isSurveyScheduled'], {
        is: (isScheduled) => {
          return isScheduled && isSurveyStartDateChanged;
        },
        // the past date is acceptable and should be allowed since the schedule already started on that past date
        then: Yup.date().nullable(),
        otherwise: Yup.date()
          .min(
            futureDate,
            'Survey Start Date must be at least one day in the future'
          )
          .required('Required Survey Start Date'),
      }),
      otherwise: Yup.date().nullable(),
    }),
    surveyEndDate: Yup.date().when(
      ['isSurveyScheduled', 'surveyStartDate', 'scheduleRate'],
      {
        is: (isSurveyScheduled, surveyStartDate, rate) =>
          isSurveyScheduled && !!surveyStartDate && rate !== 'Once',
        then: Yup.date().when(
          ['isSurveyScheduled', 'surveyStartDate', 'scheduleRate'],
          {
            is: (isSurveyScheduled, surveyStartDate, rate) =>
              isSurveyScheduled &&
              !!surveyStartDate &&
              rate !== 'Once' &&
              isSurveyStartDateChanged &&
              isSurveyEndDateChanged,
            // the past date is acceptable and should be allowed since the schedule already started on that past date
            then: Yup.date().nullable(),
            otherwise: Yup.date()
              .min(
                Yup.ref('surveyStartDate'),
                'Survey End Date must be after Start Date'
              )
              .test(
                'is-in-future',
                'Survey End Date cannot be in the past',
                (value) => {
                  return !value || value >= futureDate;
                }
              )
              .required('Required Survey End Date'),
          }
        ),
        otherwise: Yup.date().nullable(),
      }
    ),
    surveyTime: Yup.string().when('isSurveyScheduled', {
      is: true,
      then: Yup.string().required('Required Survey Start Time'),
    }),
    questionSet: Yup.array().of(
      Yup.object().shape({
        value: Yup.lazy((itmVal) => {
          const {
            existingPPI: { value: ppiValue } = { label: '', value: '' },
            answerType: questionType,
            conditionalToggle: isConditionalToggled,
            categoryChoice,
          } = getFieldValuesByFieldNames(itmVal, [
            QuestionSetTypes.EXISTING_PPI,
            QuestionSetTypes.ANSWER_TYPE,
            QuestionSetTypes.CONDITIONAL_TOGGLE,
            QuestionSetTypes.CATEGORY_CHOICE,
          ]);
          const isCorePPI = !!ppiAnswerOptions(ppiValue);

          return Yup.array().of(
            Yup.object().shape({
              value: Yup.mixed()
                //eslint-disable-next-line
                .test('required', requiredError, function (val1, opt1) {
                  const { field: fields, value } = this.parent || {};
                  if (categoryChoice) {
                    if (
                      fields.includes(QuestionSetTypes.CATEGORY_NAME) &&
                      isEmptyValue(value)
                    ) {
                      return false;
                    }
                    return true;
                  }

                  if (fields.includes(QuestionSetTypes.ANSWER_OPTIONS)) {
                    if (['ppi-height', 'ppi-weight'].includes(ppiValue)) {
                      return true;
                    }
                    const options = propOr([], 'value', this.parent);
                    const minimumOptions = options.length >= 2;
                    const hasEmptyValue = !isCorePPI
                      ? options.some(
                        ({ value }) => value === undefined || value === ''
                      )
                      : options.some(
                        (value) => value === undefined || value === ''
                      );

                    if (!minimumOptions) {
                      if (isTextOrHiddenType(questionType)) {
                        return true;
                      } else if (!minimumOptions) return false;

                      return createCustomError(
                        this,
                        val1,
                        atLeastTwoOptionsError
                      );
                    }
                    if (hasEmptyValue) {
                      return false;
                    }
                  }

                  if (fields.includes(QuestionSetTypes.ANSWER_TYPE)) {
                    if (['ppi-height', 'ppi-weight'].includes(ppiValue)) {
                      return true;
                    }
                    if (!value) {
                      // Exclude non-option requiring PPI
                      return false;
                    }
                  }

                  if (
                    fields.includes(QuestionSetTypes.CONDITIONAL_OPTIONS) &&
                    isConditionalToggled &&
                    (!value || isEmpty(value))
                  ) {
                    return false;
                  }

                  const validationResult = checkCategoryFieldValidity(
                    fields,
                    value,
                    val1,
                    this
                  );
                  return validationResult !== null ? validationResult : true;
                })
                //eslint-disable-next-line
                .test('duplicate', 'Duplicate', function (val1) {
                  const { field: fields } = this.parent;
                  if (
                    fields.includes(QuestionSetTypes.ANSWER_OPTIONS) &&
                    !isCorePPI
                  ) {
                    const options = propOr([], 'value', this.parent);

                    const duplicateValues = options
                      .map((option) => option.value?.toLowerCase())
                      .filter(
                        (value, index, self) => self.indexOf(value) !== index
                      );

                    const duplicateOption = val1?.find((item) =>
                      duplicateValues.includes(item.value?.toLowerCase())
                    );

                    if (duplicateOption) {
                      const duplicateValue = duplicateOption.value;

                      return createCustomError(
                        this,
                        val1,
                        `${ duplicateAnswerOption }: ${ duplicateValue }`
                      );
                    }
                  }

                  return true;
                }),
            })
          );
        }),
      })
    ),
  });
};

export const validationSchema = {
  overviewCard,
  webOverviewCard,
  webDetailPage,
  mobileOverviewCard,
  mobileReturningCard,
  mobileDetailScreen,
  consentDocument,
  screener,
  survey,
  defaultMedia: defaultAsset,
};

export function convertHandleToTitle(text = '') {
  return text
    ?.split('-')
    .join(' ')
    .toLowerCase()
    .split(' ')
    .map((string) => string.charAt(0).toUpperCase() + string.substring(1))
    .join(' ');
}

export const getFieldLabel = (label, order) =>
  ({
    title: 'Headline',
    subtitle: 'Tagline',
    ctaText: 'CTA Text',
    richText: 'Text',
    backgroundImage: 'Background Image',
    logoImage: 'Logo Image',
    repeatingText: `Section ${ order }`,
    questionSet: `Question ${ order }`,
  }[label] || convertHandleToTitle(label));

export function convertHandleToCardType(text) {
  return (
    text?.charAt(0).toLowerCase() +
    text
      ?.split('-')
      .join(' ')
      .toLowerCase()
      .split(' ')
      .map((string) => string.charAt(0).toUpperCase() + string.substring(1))
      .join(' ')
      .split(' ')
      .join('')
      .slice(1)
  );
}

export const getRepeatingTextFields = (itm) => ({
  field: Math.random(),
  type: itm?.type || 'repeatingText',
  value: itm?.text || '',
  sortOrder: itm?.sortOrder || 1,
  helperText: itm?.helperText || 'You may use rich text formatting',
});

// eslint-disable-next-line max-params
function getQuestionSetFields(
  questionSet,
  randomInt,
  categoryOptions,
  index,
  options = []
) {
  const fieldSetId = randomInt || getRandomInt();

  const itm = questionSet?.value;

  const ppiList = options
    .filter((opt) => opt.value !== 'ppi-last-name')
    .filter((opt) => opt.value !== 'ppi-first-name')
    .filter((opt) => opt.value !== 'ppi-date-of-birth')
    .filter((opt) => opt.value !== 'ppi-email')
    .filter((opt) => opt.value !== 'ppi-phone-number');

  return {
    field: 'questionSet',
    type: 'questionSet',
    sortOrder: questionSet ? questionSet.sortOrder : index + 1,
    fieldSetId,
    value: [
      {
        field: `${ QuestionSetTypes.CONDITIONAL_TOGGLE }-${ fieldSetId }`,
        label: 'Conditional Toggle',
        sortOrder: 8,
        type:
          getFieldByFieldName(itm, QuestionSetTypes.CONDITIONAL_TOGGLE)?.type ||
          'toggle',
        value:
          getFieldValueByFieldName(itm, QuestionSetTypes.CONDITIONAL_TOGGLE) ||
          false,
      },
      {
        field: `${ QuestionSetTypes.CONDITIONAL_OPTIONS }-${ fieldSetId }`,
        label: 'Select the answer option(s) to display this question',
        sortOrder: 9,
        type:
          getFieldByFieldName(itm, QuestionSetTypes.CONDITIONAL_OPTIONS)
            ?.type || 'select',
        value: getFieldValueByFieldName(
          itm,
          QuestionSetTypes.CONDITIONAL_OPTIONS
        ),
        options:
          getFieldByFieldName(itm, QuestionSetTypes.CONDITIONAL_OPTIONS)
            ?.options || ppiList,
      },
      {
        field: `${ QuestionSetTypes.QUESTION_LABEL }-${ fieldSetId }`,
        sortOrder: 10,
        type:
          getFieldByFieldName(itm, QuestionSetTypes.QUESTION_LABEL)?.type ||
          'text',
        value: getFieldValueByFieldName(itm, QuestionSetTypes.QUESTION_LABEL),
        label:
          getFieldByFieldName(itm, QuestionSetTypes.QUESTION_LABEL)?.label ||
          'Question',
      },
      {
        field: `${ QuestionSetTypes.QUESTION_CATEGORY }-${ fieldSetId }`,
        label:
          getFieldByFieldName(itm, QuestionSetTypes.QUESTION_CATEGORY)?.label ||
          'Category',
        sortOrder: 1,
        type:
          getFieldByFieldName(itm, QuestionSetTypes.QUESTION_CATEGORY)?.type ||
          'select',
        value: getFieldValueByFieldName(
          itm,
          QuestionSetTypes.QUESTION_CATEGORY
        ),
        isCreatable: true,
        options: categoryOptions
          ? categoryOptions.map((cat) => {
            return { label: cat, value: cat };
          })
          : [],
      },
      {
        field: `${ QuestionSetTypes.CATEGORY_NAME }-${ fieldSetId }`,
        label:
          getFieldByFieldName(itm, QuestionSetTypes.CATEGORY_NAME)?.label ||
          'Category Name',
        sortOrder: 2,
        type:
          getFieldByFieldName(itm, QuestionSetTypes.CATEGORY_NAME)?.type ||
          'text',
        value:
          getFieldValueByFieldName(itm, QuestionSetTypes.CATEGORY_NAME) ||
          //Check with Kathy on parsing category type
          getFieldValueByFieldName(itm, QuestionSetTypes.QUESTION_CATEGORY)
            ?.value,
        className: 'categoryFields',
        message,
      },
      {
        field: `${ QuestionSetTypes.CATEGORY_TITLE }-${ fieldSetId }`,
        label:
          getFieldByFieldName(itm, QuestionSetTypes.CATEGORY_TITLE)?.label ||
          'Category Title',
        sortOrder: 3,
        type:
          getFieldByFieldName(itm, QuestionSetTypes.CATEGORY_TITLE)?.type ||
          'text',
        value: getFieldValueByFieldName(itm, QuestionSetTypes.CATEGORY_TITLE),
        className: 'categoryFields',
        message,
      },
      {
        field: `${ QuestionSetTypes.CATEGORY_SUBTITLE }-${ fieldSetId }`,
        label:
          getFieldByFieldName(itm, QuestionSetTypes.CATEGORY_SUBTITLE)?.label ||
          'Category Subtitle',
        sortOrder: 4,
        type:
          getFieldByFieldName(itm, QuestionSetTypes.CATEGORY_SUBTITLE)?.type ||
          'text',
        value: getFieldValueByFieldName(
          itm,
          QuestionSetTypes.CATEGORY_SUBTITLE
        ),
        className: 'categoryFields',
        message,
      },
      {
        field: `${ QuestionSetTypes.CATEGORY_TEXT }-${ fieldSetId }`,
        label:
          getFieldByFieldName(itm, QuestionSetTypes.CATEGORY_TEXT)?.label ||
          'Category Text',
        sortOrder: 5,
        type:
          getFieldByFieldName(itm, QuestionSetTypes.CATEGORY_TEXT)?.type ||
          'richText',
        value: getFieldValueByFieldName(itm, QuestionSetTypes.CATEGORY_TEXT),
        className: 'categoryFields',
        message,
      },
      {
        field: `${ QuestionSetTypes.BACKGROUND_IMAGE }-${ fieldSetId }`,
        label:
          getFieldByFieldName(itm, QuestionSetTypes.BACKGROUND_IMAGE)?.label ||
          'Background Image',
        sortOrder: 6,
        type:
          getFieldByFieldName(itm, QuestionSetTypes.BACKGROUND_IMAGE)?.type ||
          'backgroundImage',
        value: getFieldValueByFieldName(itm, QuestionSetTypes.BACKGROUND_IMAGE),
        className: 'categoryFields',
      },
      {
        field: `${ QuestionSetTypes.CATEGORY_CHOICE }-${ fieldSetId }`,
        label:
          getFieldByFieldName(itm, QuestionSetTypes.CATEGORY_CHOICE)?.label ||
          'This category does not have questions associated with it',
        sortOrder: 7,
        type:
          getFieldByFieldName(itm, QuestionSetTypes.CATEGORY_CHOICE)?.type ||
          'choice',
        value: getFieldValueByFieldName(itm, QuestionSetTypes.CATEGORY_CHOICE),
        className: 'categoryFields',
        //For already saved question disabled the the choice option.
        disabled:
          getFieldValueByFieldName(itm, QuestionSetTypes.QUESTION_LABEL) ||
          false,
      },
      {
        field: `${ QuestionSetTypes.EXISTING_PPI }-${ fieldSetId }`,
        label:
          getFieldByFieldName(itm, QuestionSetTypes.EXISTING_PPI)?.label ||
          'Associated Profile Property (PPI)',
        sortOrder: 11,
        type:
          getFieldByFieldName(itm, QuestionSetTypes.EXISTING_PPI)?.type ||
          'select',
        value: getFieldValueByFieldName(itm, QuestionSetTypes.EXISTING_PPI),
        isCreatable: false,
        options:
          getFieldByFieldName(itm, QuestionSetTypes.EXISTING_PPI)?.options ||
          ppiList,
      },
      {
        field: `${ QuestionSetTypes.ANSWER_TYPE }-${ fieldSetId }`,
        label: 'Question Type',
        sortOrder: 12,
        type:
          getFieldByFieldName(itm, QuestionSetTypes.ANSWER_TYPE)?.type ||
          'radioSet',
        value: getFieldValueByFieldName(itm, QuestionSetTypes.ANSWER_TYPE),
        options: [
          { label: 'Single select', value: 'choice' },
          { label: 'Multi-select', value: 'multi' },
          { label: 'Text', value: 'text' },
          { label: 'Hidden', value: 'hidden' },
        ],
      },
      {
        field: `${ QuestionSetTypes.ANSWER_OPTIONS }-${ fieldSetId }`,
        label: 'Options',
        sortOrder: 13,
        type:
          getFieldByFieldName(itm, QuestionSetTypes.ANSWER_OPTIONS)?.type ||
          'tagsInput',
        value: getFieldValueByFieldName(itm, QuestionSetTypes.ANSWER_OPTIONS),
        //Passing the associated ppi-handle
        ppiHandle: getFieldValueByFieldName(itm, QuestionSetTypes.EXISTING_PPI)
          ?.value,
      },
    ],
  };
}

export function getFormFields(content, layoutType = 'defaultMedia', options) {
  const selectedDataSections = propOr([], 'sections', content);
  const selectedDataQuestions = propOr([], 'questionSet', content);

  const categoryOptions = content?.categories
    ? content?.categories.map((cat) => cat.handle)
    : [];

  const totalQuestions = sum(
    propOr([], 'categories', content).map((cat) => cat.questions.length)
  );

  const kebabCaseLayoutType = kebabCase(layoutType);
  const isWebCard = [WEB_OVERVIEW_CARD, WEB_DETAIL_PAGE].includes(
    kebabCaseLayoutType
  );
  const isWebOverviewCard = kebabCaseLayoutType === WEB_OVERVIEW_CARD;
  const isMobileDetailCard = kebabCaseLayoutType === MOBILE_DETAIL_SCREEN;

  const elements = (filterBy = '') =>
    [
      {
        field: 'title',
        type: 'text',
        value: content?.title || '',
        maxChars: 40,
      },
      {
        field: 'subtitle',
        type: 'text',
        value: content?.subtitle || '',
        maxChars: 85,
      },
      {
        field: 'text',
        type: isWebOverviewCard ? 'textArea' : 'richText',
        value: content?.text || '',
        maxChars: 300,
      },
      {
        field: 'ctaText',
        type: 'text',
        value: content?.ctaText || '',
        maxChars: 15,
      },
      {
        field: 'logoImage',
        type: 'image',
        value: content?.logoImage || null,
        helperText: `Please upload a white logo as a transparent PNG or GIF./Recommended size: ${
          isWebCard ? '480' : '240'
        }px x 168px./Max file size: 1 MB.`,
      },
      {
        field: 'backgroundImage',
        type: 'image',
        value: content?.backgroundImage || null,
        helperText: `Recommended size: ${
          isWebCard
            ? '2010px x 830'
            : isMobileDetailCard
              ? '1125px x 1812'
              : '984px x 1392'
        }px./Max file: size 1 MB.`,
      },
      {
        field: 'repeatingText',
        type: 'repeatingText',
        value:
          selectedDataSections.length > 0
            ? selectedDataSections.map((itm) => {
              return getRepeatingTextFields(itm);
            })
            : [getRepeatingTextFields()],
      },
      {
        field: 'screener',
        type: 'screener',
        value:
          selectedDataQuestions.length > 0
            ? selectedDataQuestions.map((itm, idx) => {
              return getQuestionSetFields(
                itm,
                null,
                categoryOptions,
                idx,
                options
              );
            })
            : [
              getQuestionSetFields(
                null,
                null,
                categoryOptions,
                totalQuestions,
                options
              ),
            ],
      },
      {
        field: 'survey',
        type: 'survey',
        value:
          selectedDataQuestions.length > 0
            ? selectedDataQuestions.map((itm, idx) => {
              return getQuestionSetFields(
                itm,
                null,
                categoryOptions,
                idx,
                options
              );
            })
            : [
              getQuestionSetFields(
                null,
                null,
                categoryOptions,
                totalQuestions,
                options
              ),
            ],
      },
    ].filter((item) => {
      if (filterBy.length) {
        const fieldType = Array.isArray(item) ? item[0]?.field : item?.field;
        const result = filterBy.includes(fieldType);
        return result;
      }
      return true;
    });

  return {
    mobileOverviewCard: {
      elements: elements([
        'title',
        'subtitle',
        'ctaText',
        'backgroundImage',
        'logoImage',
      ]),
    },
    mobileReturningCard: {
      elements: elements(['backgroundImage', 'logoImage']),
    },
    mobileDetailScreen: {
      elements: elements([
        'title',
        'subtitle',
        'text',
        'ctaText',
        'backgroundImage',
        'logoImage',
      ]),
    },
    webDetailPage: {
      elements: elements([
        'title',
        'subtitle',
        'text',
        'ctaText',
        'backgroundImage',
        'logoImage',
      ]),
    },
    webOverviewCard: {
      elements: elements([
        'title',
        'subtitle',
        'text',
        'ctaText',
        'backgroundImage',
        'logoImage',
      ]),
    },
    consentDocument: {
      elements: elements(['repeatingText']),
    },
    screener: {
      elements: elements(['screener']),
    },
    survey: {
      elements: elements(['survey']),
    },
    defaultMedia: {
      elements: elements(['title', 'subtitle', 'logoImage', 'backgroundImage']),
    },
  }[layoutType];
}

function getRandomInt() {
  return Math.floor(Math.random() * 9999999);
}

const restrictedPPIs = [
  'ppi-activity-gear-brand',
  'ppi-activity-gear-image',
  'ppi-activity-gear-name',
  'ppi-activity-gear-type',
];

export const filterPPI = ({ value }) => !restrictedPPIs.includes(value);

export const getSurveyScheduleRate = (rate) => {
  if (rate && rate.unit === 'days') {
    if (rate.value === 1) {
      return 'Daily';
    } else if (rate.value === 7) {
      return 'Weekly';
    }
  }
  return 'Once';
};
