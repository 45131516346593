import { requiredError } from 'components/forms/utils';
import { pickAll, pathOr, mergeAll, fromPairs } from 'ramda';

// This function sets the handle, name, shortHandle backgroundImage details if doesn't exist
// and updates if exists for sub-nav-obj local storage for below situation
// Earlier it used to handle only when user clicks on the edit exp option from action menu.
// Now, it handles below cases
// 1. When user clicks on the experience-list row
// 2. When user modifies the Edit Exp - default Media -> background-image then SubNav Logo will also be updated

export const saveInitNavData = (row, isRow = true) => {
  const navObj = JSON.parse(window?.localStorage?.getItem('sub-nav-obj'));

  const defaultMediaImage = isRow
    ? pathOr('', ['defaultMedia', 'backgroundImage'], row)
    : pathOr('', ['backgroundImage'], row);

  const subNavObj = isRow
    ? JSON.stringify(
      mergeAll([
        pickAll(['handle', 'name', 'shortHandle'], row),
        fromPairs([['defaultMediaImage', defaultMediaImage]]),
      ])
    )
    : JSON.stringify({ ...navObj, defaultMediaImage });

  window?.localStorage?.setItem('sub-nav-obj', subNavObj);
};

export const InitiativeTypes = [
  'initiative/polls',
  'initiative/experiences',
  'initiative/core',
];

export const InitiativeTypesEnum = {
  COR: 'core',
  EXP: 'digital-service',
  QTX: 'activity-survey',
};

export const InitiativeTypeList = [
  InitiativeTypesEnum.COR,
  InitiativeTypesEnum.EXP,
  InitiativeTypesEnum.QTX,
  'experiences', //for backwards compatibility
];

export const AudienceTypesEnum = {
  INIT_AUDIENCE: 'Initiative Static',
  DYNAMIC_AUDIENCE: 'Dynamic',
  STAT_AUDIENCE: 'Static',
  SHARED_AUDIENCE: 'Shared',
};

// Extracting the required fields from the validation schema
export const getRequiredFields = (validationSchema) =>
  validationSchema.fields &&
  Object.keys(validationSchema.fields).reduce((requiredFields, field) => {
    if (
      validationSchema.fields[field].tests.some(
        (test) => test.OPTIONS.name === requiredError.toLowerCase()
      )
    ) {
      requiredFields[field] = true;
    }
    return requiredFields;
  }, {});
