import React from 'react';
import PropTypes from 'prop-types';
import FieldWrapper from 'components/FieldWrapper';
import { Field } from 'formik';
import { getFieldLabel } from '../../lib';
import { TextField } from '@nike/eds';
import { QuestionSetTypes } from '../../RepeatableFieldsetUtils';

export const TextComponent = ({
  fieldId,
  fieldLabel,
  fieldValue,
  fieldName,
  fieldType,
  fieldHelperText,
  className,
  placeholder,
  disabled,
  hasErrors,
  requiredFields,
  onChange,
  errorMessage,
}) => (
  <Field
    key={ fieldId }
    name={ fieldName }
    placeholder={ placeholder || fieldName }
    className={ className }
  >
    { /* eslint-disable-next-line  */ }
    {({ field, meta }) => (
      <FieldWrapper disabled={ disabled } className={ className }>
        <TextField
          hasErrors={ !!(meta.touched && hasErrors) }
          errorMessage={ meta.touched && hasErrors && errorMessage }
          disabled={ disabled }
          key={ fieldId }
          type={ fieldType }
          id={ fieldName }
          name={ fieldName }
          defaultValue={ fieldValue || '' }
          className={ className }
          value={ meta.touched ? field.value || fieldValue : fieldValue || '' }
          label={
            <label>
              { fieldLabel || getFieldLabel(field.name) }
              { (requiredFields[fieldName] ||
                fieldName.includes(QuestionSetTypes.QUESTION_LABEL) ||
                fieldName.includes(QuestionSetTypes.CATEGORY_NAME)) &&
                !(meta.touched && hasErrors) && (
                <span className="asterisk">*</span>
              ) }
            </label>
          }
          message={ fieldHelperText }
          onChange={ onChange }
        />
      </FieldWrapper>
    ) }
  </Field>
);

TextComponent.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  fieldHelperText: PropTypes.string,
  fieldId: PropTypes.string,
  fieldLabel: PropTypes.string,
  fieldName: PropTypes.string,
  fieldType: PropTypes.string,
  fieldValue: PropTypes.string,
  hasErrors: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  requiredFields: PropTypes.shape(Object),
};
